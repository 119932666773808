<template>
  <div>
    <v-container>
      <snackbar :attr="snackbar" />
      <!-- <v-row align="center" justify="center">
        <v-col v-if="validToken" cols="12" sm="8" md="6" lg="5">
          <v-alert type="success">
            Please update your password.
          </v-alert>
        </v-col>
      </v-row> -->
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="5">
          <!-- {{ token }} {{ id }} -->
          <v-card class="elevation-2 mt-8">
            <v-toolbar v-if="validToken" dark flat color="success">
              <v-toolbar-title>Please update your password.</v-toolbar-title>
            </v-toolbar>
            <!-- <v-divider></v-divider> -->
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="password"
                  name="password"
                  label="Password*"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  required
                  autocomplete="password"
                  icon-append
                  ><vue-fontawesome
                    slot="append"
                    color="grey"
                    @click="showPassword = !showPassword"
                    :icon="showPassword ? 'eye' : 'eye-slash'"
                /></v-text-field>

                <v-text-field
                  v-on:keyup.enter="resetPassword"
                  v-model="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password*"
                  :type="showConfirmedPassword ? 'text' : 'password'"
                  :rules="[this.password === this.confirmPassword]"
                  required
                  icon-append
                  ><vue-fontawesome
                    slot="append"
                    color="grey"
                    @click="showConfirmedPassword = !showConfirmedPassword"
                    :icon="showConfirmedPassword ? 'eye' : 'eye-slash'"
                /></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                color="grey darken-3"
                class="ma-2 white--text"
                @click="resetPassword"
                :disabled="!valid"
                >Reset Password</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import AgencyService from '@/services/AgencyService'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'ResetPassword',
  components: { Snackbar },
  data: () => ({
    snackbar: {
      model: false,
      color: '',
      message: '',
      icon: ['fas', 'exclamation-triangle'],
      timeout: 3000
    },
    redirect: true,
    validToken: false,
    valid: false,
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length > 5 || 'Name must be more than 5 characters'
    ],
    confirmPassword: '',
    confirmPasswordRules: [
      v => !!v || 'Password is required',
      v => v === this.password || 'Confirmed password does not match'
    ],
    showPassword: false,
    showConfirmedPassword: false,
    resetPasswordPanel: false,
    hasError: false
  }),
  // created() {
  //   console.log()
  // }
  async created() {
    try {
      const id = this.$route.query.id
      const tokenResponse = await UserService.checkToken(id)
      if (!tokenResponse) {
        return
      }
      this.validToken = true
    } catch (error) {
      this.snackbar.model = true
      this.snackbar.message = 'Token not found.'
      this.snackbar.color = 'red'
      this.snackbar.icon = ['fas', 'exclamation-triangle']
      this.redirect = setTimeout(
        function() {
          this.$router.push({ path: '/' })
        }.bind(this),
        3000
      )
      console.log(error)
    }
  },
  computed: {
    token() {
      return this.$route.query.token || null
    },
    id() {
      return this.$route.query.id || null
    }
  },
  methods: {
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    async resetPassword() {
      // console.log(this.password)
      if (this.valid) {
        try {
          const resetData = {
            id: this.id,
            token: this.token,
            password: this.password
          }
          // console.log(resetData)
          const userResponse = await UserService.resetPassword(resetData)
          console.log(userResponse)
          // let response = await this.$http.post('/user/login', this.login)
          const token = userResponse.data.token
          const user = userResponse.data.user

          // now set need to set the agency
          const agency = {
            id: userResponse.data.user.agency._id,
            name: userResponse.data.user.agency.name,
            abbr: userResponse.data.user.agency.abbr
          }
          localStorage.setItem('token', token)
          // user.token = token
          // get agencies
          const agenciesResult = await AgencyService.getAll()
          if (!agenciesResult) {
            return
          }
          // console.log(agenciesResult)
          const agencies = agenciesResult.data
          if (token) {
            await this.$store.dispatch('setAgency', { agency })
            await this.$store.dispatch('setAgencies', { agencies })
            await this.$store.dispatch('login', { user })
            this.$router.push('/')
          }
        } catch (err) {
          this.snackbar.model = true
          this.snackbar.message = err.response.data.msg
          // this.snackbar.message = 'Wrong username or password!'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          // console.log(err.response.data)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  font-weight: 500;
  letter-spacing: 0;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: none;
}
</style>
